document.addEventListener('DOMContentLoaded', function() {
  const scrollLink = document.getElementById('scrollLink');
  const target = document.querySelector('#contact');
  if (scrollLink && target) {
    scrollLink.addEventListener('click', function(event) {
      event.preventDefault();
      target.scrollIntoView({ behavior: 'smooth' });
    });
  }
});

// async function fakeApiFetch(url, options) {
//   return new Promise((resolve, reject) => {
//     // Simulate API response after a short delay (e.g., 1 second)
//     setTimeout(() => {
//       // Resolve with a response object with ok set to true
//       resolve({
//         ok: true,
//         status: 200,
//         statusText: 'OK',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         json: () => Promise.resolve({}) // Simulate JSON response
//       });
//     }, 1000); // Simulate delay of 1 second
//   });
// }

// // Example usage with async/await
// async function sendForm() {
//   const formContentDiv = document.getElementById('formContent');
//   const successContentDiv = document.getElementById('successContent');
//   try {
//     const response = await fakeApiFetch('https://example.com/api/data', {
//       method: 'POST',
//       headers: {
//         'Authorization': 'Bearer your-access-token'
//       }
//     });
//     console.log('Success:', response);
//     formContentDiv.classList.add('hidden');
//     successContentDiv.classList.remove('hidden');
//     // Handle successful response
//   } catch (error) {
//     console.error('Error:', error);
//     // Handle error
//   }
// }

// document.getElementById('contactForm')
//   .addEventListener('submit', function (event) {
//     event.preventDefault();
//     const nameField = document.getElementById('name');
//     const emailField = document.getElementById('email');
//     const agreeCheckbox = document.getElementById('agree');

//     const nameError = document.getElementById('nameError');
//     const emailError = document.getElementById('emailError');
//     const agreeError = document.getElementById('agreeError');
//     // Reset previous error messages
//     nameError.textContent = '';
//     emailError.textContent = '';
//     agreeError.textContent = '';
    
//     let error = false;
//     // Validate name field
//     if (!nameField.value.trim()) {
//       nameError.textContent = 'Föreningens namn är obligatoriskt.';
//       error = true;
//     }

//     // Validate email field
//     if (!emailField.value.trim()) {
//       emailError.textContent = 'E-postadress är obligatoriskt.';
    
//       error = true;
//     } else if (!isValidEmail(emailField.value)) {
//       emailError.textContent = 'Ange en giltig e-postadress.';
//       error = true;
//     }

//     // Validate checkbox
//     if (!agreeCheckbox.checked) {
//       agreeError.textContent = 'Du måste acceptera villkoren för att skicka formuläret.';
//       error = true;
//     }

//     if(!error) {
//       console.log('No errors, sending form');
//       sendForm();
//     }
//   });



// Function to validate email format
// function isValidEmail(email) {
//   var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailRegex.test(email);
// }
